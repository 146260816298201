<div class="nav" #nav (window:resize)="onResize($event)">
    <div class="actions" *ngIf="!inputMode">
        <span>{{cursorMode}} mode</span>&nbsp;
        <div style="display: inline-block;">&nbsp;</div>
        <!--
        <ng-template [ngIf]="!pause">
            <button class="btn btn-secondary" (click)="getWeatherClick()">
                <span class="mdi mdi-weather-partly-cloudy"></span> Weather
            </button>
        </ng-template>
    -->
        <ng-template [ngIf]="pause">
            <button class="btn btn-secondary" [class.has-range]="cursorMode==='ranged'" (click)="nextPointClick()"><span class="mdi mdi-select"></span> Create range</button>
            <button class="btn btn-secondary" (click)="addPoint()"><span class="mdi mdi-plus-circle-outline"></span>
                Save <span *ngIf="cursorMode==='range'">point</span> <span *ngIf="cursorMode==='ranged'">range</span></button>
            <button class="btn btn-secondary" (click)="annotatePoint()" *ngIf="canAnnotate"><span class="mdi mdi-plus-circle-outline"></span> Annotate <span *ngIf="cursorMode==='range'">point</span>
                <span *ngIf="cursorMode==='ranged'">range</span></button>
            <span (click)="pause=false;cursorMode='free'" class="mdi mdi-window-close"></span>
        </ng-template>

    </div>
    <div class="input" *ngIf="inputMode">
        <input autofocus [(ngModel)]="inputText">
        <button class="btn btn-secondary">
            <span class="mdi mdi-content-save-edit-outline"></span>
            Save
        </button>

        <button class="btn btn-secondary" (click)="inputMode=null">
            <span class="mdi mdi-window-close"></span>
            Cancel
        </button>
    </div>
</div>

<article>
    @if(item?.telemetry?.d && (!hasToClickToInteract || (hasToClickToInteract && hasClicked))) {
    <section class="info" #info [style.left.px]="mouseX" [style.top.px]="mouseY">
        <p>&nbsp; {{(item.telemetry.d) | amDateFormat:'ddd Do MMMM HH:mm'}} &nbsp;</p>
        @if(pause) {
        <nav>
            <i class="fa fa-fw fa-pencil" pTooltip="Annotate" tooltipPosition="left" (click)="annotateClick()" *ngIf="canAnnotate"></i>
            <!--<i class="fa fa-fw fa-drivers-license-o" pTooltip="Asset Dashboard" tooltipPosition="left"
                (click)="assetDashboardClick(item)"></i>-->
            <i *ngIf="canZoom" class="fa fa-fw fa-search-plus" pTooltip="Zoom in here" (click)="zoomIn(item.telemetry.d)"></i>
            <i *ngIf="history.length && canZoom" class="fa fa-fw fa-search-minus" pTooltip="Zoom out" (click)="zoomOut()"></i>
            <i class="fa fa-fw fa-times" pTooltip="Close" (click)="pause=false"></i>
        </nav>
        }

        <div class="hover-values">
            <p><span>Value</span> {{item.telemetry.v}}</p>
            @if(item.telemetry.rag) {
            <p><span>RAG</span>
                <span class="red" [style.opacity]="item.telemetry.rag === 'red' ? 1 : .2"><i class="fa fa-fw fa-circle"></i></span>
                <span class="orange" [style.opacity]="item.telemetry.rag === 'amber' ? 1 : .2"><i class="fa fa-fw fa-circle"></i></span>
                <span class="green" [style.opacity]="item.telemetry.rag === 'green' ? 1 : .2"><i class="fa fa-fw fa-circle"></i></span>
            </p>
            }
            @if(item.telemetry.w) {
            <p>
                <span>Ambient</span>&nbsp;
                <app-weather-temp [weather]="{icon:item.telemetry.w.i, temp:item.telemetry.w.t}"></app-weather-temp>
            </p>
            }
            @if(item.telemetry.annotation){
            <p class="annotation-text">{{item.telemetry.annotation.text}}</p>
            }
            @if(item.pin) {
            <div>
                <div><i class="mdi mdi-pin"></i> {{item.pin.text}}</div>
                <div class="italic">{{item.pin.date | amDateFormat:'ddd Do MMMM HH:mm'}}
                </div>
            </div>
            }
            @if(item.annotation) {
            <div>
                <div><i class="mdi mdi-pin"></i> {{item.annotation.text}}</div>
                <div class="italic">{{item.annotation.createdAt | amDateFormat:'ddd Do MMMM HH:mm'}}</div>
            </div>
            }
        </div>
    </section>
    }
</article>

@if(isShowingPanel()) {
<app-dialog header="Annotation" (onClose)="isShowingPanel.set(false)" [dimensions]="{width:600, height:300}" [hasToolbar]="true">
    <div class="toolbar">
        <app-toolbar>
            <div class="right">
                @if(item.telemetry.annotation?.text) {
                <app-button label="Remove" icon="mdi mdi-trash-can" (click)="annotationDelete()" />
                }
                <app-button icon="mdi mdi-check" label="Update" (click)="annotationSave()" />
            </div>
        </app-toolbar>
    </div>
    @if(item) {
    <div class="annotation">
        <div>
            <p>Annotation at <strong>{{item.telemetry.d | amDateFormat:'ddd Do MMMM HH:mm'}}</strong> for value of
                <app-chip [label]="' ' + item.telemetry.v + ' '" />
            </p>
        </div>
        <div>
            <div><strong>Notes</strong></div>
            <textarea class="fourd" rows="5" [(ngModel)]="annotation.text"></textarea>
        </div>
    </div>
    } @else {
    <i class="mdi mdi-loading mdi-spin-x2"></i>
    }
</app-dialog>
}

@if(isShowingAssetDashboard()) {
<div class="fullscreen">
    <app-asset-dashboard [asset]="asset" (onGoBack)="isShowingAssetDashboard.set(false)"></app-asset-dashboard>
</div>
}
<svg>
</svg>
