<div
  class="dropdown-container"
  [class.custom-width]="autoFit()"
  [class.has-border]="hasBorder()"
  (click)="chevronClick()"
  [class.is-block]="isBlock()"
  #dropdown>
  <span>{{ label() }}</span>
  <div>
    <i class="mdi mdi-chevron-down"></i>
  </div>
  @if (listVisible()) {
    <div class="dropdown-list" [style.height]="useHeight()" [style.width]="width()">
      <div>
        <ul>
          @for (item of list(); track item.id) {
            <li (click)="listItemClick(item)">
              {{ item.title }}
            </li>
          }
        </ul>
      </div>
    </div>
  }
</div>
