import { Component, EventEmitter, Input, Output, input, signal } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent {

  _size = signal<ButtonSize>('medium');
  _label = signal('');
  _icon = signal('');
  _selected = signal<boolean>(null);
  _isMuted = signal<boolean>(false);
  _isDisabled = signal<boolean>(false);
  iconPos = input<ButtonIconPosition>('left');
  rightAction = input<ActionButton>(null);
  margin = input<string>('1px');
  hasRightBorder = input<boolean>(true);
  hasLeftBorder = input<boolean>(true);
  isCTA = input<boolean>(false);
  isDanger = input<boolean>(false);

  @Input()
  public set label(v: string) {
    this._label.set(v);
  }

  @Input()
  public set size(v: ButtonSize) {
    this._size.set(v);
  }

  @Input()
  public set icon(v: string) {
    this._icon.set(v);
  }

  @Input()
  public set selected(v: boolean) {
    this._selected.set(v);
  }

  @Input()
  public set isMuted(v: boolean) {
    this._isMuted.set(v);
  }
  public get isMuted(): boolean {
    return this._isMuted();
  }

  @Input()
  public set isDisabled(v: boolean) {
    this._isMuted.set(v);
    this._isDisabled.set(v);
  }

  @Output() onAction = new EventEmitter<ActionButton>();

  actionClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.onAction.emit(this.rightAction());
  }
}

export type ButtonSize = 'small' | 'medium';
export type ActionButton = 'configure';
export type ButtonIconPosition = 'left' | 'right' | 'top' | 'bottom';
